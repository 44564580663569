<template>
  <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background"></div>
      <div class="modal-card">
          <header class="modal-card-head"></header>
          <section class="modal-card-body">
            <div class="main">
              <p>
                この宅配ボックスは商品発送エリアに対応しておりません。
              </p>
              <p>
                ※対応エリアは<a href="https://smari.io/terms#area" target="_blank" class="area-link is-underlined">こちら</a>
              </p>
              <div class="deliverybox-info">
                <p>
                  {{ store_address }}<br />
                  {{ store_name }}&nbsp;&nbsp;{{ store_locker_name }}
                </p>
              </div>
            </div>
            <div class="sub">
              <p>
                ローソンなどに設置しているスマリボックスなど、他の発送方法をご利用ください。<br />
                スマリボックス設置拠点については
                <a href="https://smari.io/location" target="_blank" class="area-link is-underlined">こちら</a><br />
                <br />
                発送しない方はブラウザを閉じてください。
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
              <div class="button-wrapper">
                  <button
                      class="button btn-cancel"
                      @click="onClosedButton"
                      aria-label="close"
                  >
                      <span> 閉じる </span>
                  </button>
              </div>
          </footer>
      </div>
  </div>
</template>

<script>
export default {
  name: "StoreAreaErrorModal",
  props: [
    "active",
    "store_address",
    "store_name",
    "store_locker_name"
  ],
  methods: {
    onClosedButton: function () {
      this.$emit("modalClosed");
    },
  },
};
</script>

<style scoped>
p.modal-card-title {
  margin: auto;
}
.button-wrapper {
  margin: auto;
}
.main {
  font-weight: 600;
}
.deliverybox-info {
  padding-top: 10px;
  text-align: center;
}
.area-link, p {
  color: #092c0b;
}
.sub {
  margin-top: 20px;
}
</style>
