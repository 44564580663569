<template>
  <loading
    v-model:active="is_loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  <article class="message is-warning is-small" v-if="error_message">
    <div class="message-body">
      {{ error_message }}
    </div>
  </article>
  <div class="content" v-if="is_allow_action">
    <PaymentActionFlow
      payment_status="done"
      select_status="active"
      post_status="active"
      complete_status="active"
    />
    <ExplainLink />
    <div>
      <div class="qr-read">
        商品を投函するボックスを登録します。<br />
        <b>カメラ起動ボタン</b
        >を押して、ドアの<b>QRコード</b>をスキャンしてください。<br />
        <div class="btn-wrapper">
          <button class="button is-rounded btn-cancel" @click="cancel">
            キャンセル
          </button>
          <button class="button is-rounded btn-submit" @click="showModal">
            カメラ起動
          </button>
        </div>
      </div>
      <div v-if="is_show_deliverybox">
        投函先ボックスは正しいですか？
        <div class="deliverybox-info">
          <b>{{ deliverybox.store_address }}</b
          ><br />
          <b>
            {{ deliverybox.store_name }}&nbsp;&nbsp;{{
              deliverybox.store_locker_name
            }}
          </b>
        </div>
        <div class="btn-wrapper">
          <button
            class="button is-rounded btn-cancel"
            @click="clearDeliverybox"
          >
            いいえ
          </button>
          <button
            class="button is-rounded btn-submit"
            @click="decideDeliverybox"
          >
            はい
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal"
      :class="{ 'is-active': is_show_modal }"
      v-if="is_show_modal"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <qr-stream @decode="onDecode"></qr-stream>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closePaymentModal"
    ></button>
    <div
      class="modal"
      :class="{ 'is-active': is_show_payment }"
      v-if="is_show_payment"
    >
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="to-payment">
          <div class="img-wrap">
            <img src="/images/gmo_payment_image.png" alt="GMOペイメントイメージ図">
          </div>
          <p>
            決済の完了後に「サイトに戻る」ボタンをタップして、スマリサイトにお戻りください。
          </p>
          <div class="btn-wrapper">
            <button
              class="button is-rounded is-medium btn-tolinkplus"
              @click="toLinkplus"
            >
              決済サイトへ
            </button>
          </div>
        </div>
      </div>

      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closePaymentModal"
      ></button>
    </div>
    <StoreAreaErrorModal 
      :active="is_show_area_error" 
      :store_address="store_address"
      :store_name="store_name"
      :store_locker_name="store_locker_name"
      @modalClosed="onStoreAreaErrorClosed()" 
    />
  </div>
</template>
<script>
import { QrStream } from "vue3-qr-reader"
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import DeliveryboxRepository from "../repositories/DeliveryboxRepository"
import Loading from "vue-loading-overlay"
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
import ExplainLink from "../components/elements/ExplainLink.vue"
import StoreAreaErrorModal from "../components/StoreAreaErrorModal.vue";

export default {
  name: "PaymentStoreSelect",
  components: {
    QrStream,
    Loading,
    PaymentActionFlow,
    ExplainLink,
    StoreAreaErrorModal,
  },
  async created() {
    const token = this.$route.query.token
    if (!token) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    DeliveryboxReceptionRepository.findByToken(token)
      .then(() => {
        this.is_allow_action = true
      })
      .catch(() => {
        this.error_message =
          "事前受付が期限切れとなっています。最初から操作を行ってください。\nまたは、既に発送受付処理が完了している商品の可能性があります。ご確認ください。"
      })
  },
  data() {
    return {
      token: this.$route.query.token,
      is_show_modal: false,
      is_show_payment: false,
      is_loading: false,
      is_show_deliverybox: false,
      is_allow_action: false,
      deliverybox: {
        code: "",
        store_no: "",
        store_address: "",
        store_name: "",
        store_locker_name: "",
        store_locker_key: "",
      },
      error_message: "",
      is_show_area_error: false,
      store_address: "",
      store_name: "",
      store_locker_name: "",
    }
  },
  computed: {
    service() {
      return this.$store.state.service
    },
  },
  methods: {
    showModal() {
      this.is_show_modal = true
    },
    closeModal() {
      this.is_show_modal = false
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red"

        ctx.beginPath()
        ctx.moveTo(firstPoint.x, firstPoint.y)
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y)
        }
        ctx.lineTo(firstPoint.x, firstPoint.y)
        ctx.closePath()
        ctx.stroke()
      }
    },
    async onDecode(decode_string) {
      this.is_loading = true
      this.error_message = ""
      
      const decode_error_msg = 
        "選択された宅配ボックスはスマリサービスに対応していないか、すでに荷物が投函されています。別の宅配ボックスよりご発送ください。"
      
      if (decode_string.substr(0, 3) !== "BSM") {
        this.error_message = decode_error_msg
        this.is_loading = false
        this.closeModal()
        return
      }
      
      try {
        const deliverybox = await DeliveryboxRepository.findByCode(decode_string)
        this.deliverybox.code = decode_string
        this.deliverybox.store_no = deliverybox.store_no
        this.deliverybox.store_address = `${deliverybox.store_prefecture}${deliverybox.store_city}${deliverybox.store_address}${deliverybox.store_address_no}`
        this.deliverybox.store_name = deliverybox.store_name
        this.deliverybox.store_locker_name = deliverybox.store_locker_name
        this.deliverybox.store_locker_key = deliverybox.store_locker_key
        this.is_show_deliverybox = true
      } catch (error) {
        console.log(error)
        if (error?.response?.status === 400) {
          const store = error.response.data
          this.store_address = `${store.store_prefecture}${store.store_city}${store.store_address}${store.store_address_no}`
          this.store_name = store.store_name
          this.store_locker_name = store.store_locker_name
          this.is_loading = false
          this.is_show_area_error = true
          this.closeModal()
        } else {
          this.error_message = decode_error_msg
        }
      } finally {
        this.is_loading = false
        this.closeModal()
      }
    },
    clearDeliverybox() {
      this.is_show_deliverybox = false
      this.deliverybox.code = ""
      this.deliverybox.store_no = ""
      this.deliverybox.store_address = ""
      this.deliverybox.store_name = ""
      this.deliverybox.store_locker_name = ""
      this.deliverybox.store_locker_key = ""
    },
    async decideDeliverybox() {
      this.is_loading = true
      this.$store.commit("setDeliverybox", this.deliverybox)
      const update_transaction = {
        reception_status: "box_selected",
        deliverybox_code: this.deliverybox.code,
        deliverybox_key: this.deliverybox.store_locker_key,
      }
      DeliveryboxReceptionRepository.update(
        this.$route.query.token,
        update_transaction
      )
        .then(() => {
          this.is_loading = false
          this.is_show_payment = true
        })
        .catch(() => {
          this.is_loading = false
          this.error_message = "受付できませんでした。"
        })
    },
    cancel() {
      this.$store.commit("clearDeliverybox")
      this.$router.push({ path: "/payment-reception", query: this.$route.query })
    },
    async toLinkplus() {
      this.is_loading = true
      try {
        const result = await DeliveryboxReceptionRepository.createLinkUrl(this.token)

        window.location.href = result.link_url
      } catch (error) {
        if (error.response.status === 400) {
          this.error_message = "既に発送受付処理が完了している商品です。ご確認ください。"
          return
        }
        this.error_message =
          "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
        this.is_show_payment = false
      }
    },
    closePaymentModal() {
      this.is_show_payment = false
    },
    onStoreAreaErrorClosed() {
      this.is_show_area_error = false;
    }
  },
}
</script>

<style scoped>
.btn-wrapper {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
}
.deliverybox-info {
  padding-top: 10px;
  text-align: center;
}
.qr-read {
  margin-bottom: 50px;
}
.to-payment {
  padding: 80px 16px 0;
  box-sizing: border-box;
}
.to-payment p {
  color: white;
  font-weight: bold;
  margin-bottom: 12px;
}
.to-payment .img-wrap {
  width: 200px;
  margin: 0 auto 24px;
}
.modal-content {
  max-height: 100vh;
}
.btn-tolinkplus {
  font-weight: bold;
  width: 200px;
}
.to-payment .btn-wrapper {
  text-align: center;
  padding: 20px 0 50px;
}
</style>
